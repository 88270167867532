import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
  return (
    <div>
      

<footer class="bg-white rounded-lg shadow  m-4">
    <div class="w-full max-w-screen-xl mx-auto p-4 md:py-8">
        <div class="sm:flex sm:items-center sm:justify-between">
            <a href="https://flowbite.com/" class="flex items-center mb-4 sm:mb-0 space-x-3 rtl:space-x-reverse">
            <img className="h-10" src="https://cdn-icons-png.flaticon.com/128/5255/5255308.png" alt="logo" />
            <h2 className='text-2xl font-semibold '>Image Resizer</h2>
            </a>
            <ul class="flex flex-wrap items-center mb-6 text-sm font-medium text-gray-500 sm:mb-0 dark:text-gray-400">
                <Link to="/about">
                <li>
                    <p  class="hover:underline me-4 md:me-6">About</p>
                </li>
                </Link>
                <Link to="/privacy-policy">
                <li>
                    <p  class="hover:underline me-4 md:me-6">Privacy Policy</p>
                </li>
                </Link>
                <Link to="/terms-and-conditions">
                <li>
                    <p  class="hover:underline me-4 md:me-6">Terms and Conditions</p>
                </li>
                </Link>
                <Link to="/contact">
                <li>
                    <p  class="hover:underline">Contact</p>
                </li>
                </Link>
            </ul>
        </div>
        <div className='flex justify-center gap-5 mt-5'>
            <img className='h-8' src="https://cdn-icons-png.flaticon.com/128/5968/5968764.png" alt="" />
            <img className='h-8' src="https://cdn-icons-png.flaticon.com/128/15713/15713420.png" alt="" />
            <img className='h-8' src="https://cdn-icons-png.flaticon.com/128/5968/5968830.png" alt="" />
            <img className='h-8' src="https://cdn-icons-png.flaticon.com/128/3536/3536505.png" alt="" />
        </div>
        <hr class="my-6 border-gray-200 sm:mx-auto lg:my-8" />
        <span class="block text-sm text-gray-500 sm:text-center">© 2024 <a href="/" class="hover:underline">Image Resizer</a>. All Rights Reserved.</span>
    </div>
</footer>


    </div>
  )
}

export default Footer
