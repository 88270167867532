import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const NavBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <div>
      <header className="bg-white">
        <div className="mx-auto max-w-screen-xl px-4 sm:px-6 lg:px-8">
          <div className="flex h-16 items-center justify-between">
            <div className="md:flex  md:items-center md:gap-12">
              <a className="block" href="#">
                <span className="sr-only">Home</span>
                <div className='flex'>
                <img className="h-10" src="https://cdn-icons-png.flaticon.com/128/5255/5255308.png" alt="logo" />
                <h2 className='text-2xl font-semibold px-2'>Image Resizer</h2>
                </div>
              </a>
            </div>

            <div className="hidden md:block">
              <nav aria-label="Global">
                <ul className="flex items-center gap-6 text-md font-semibold">
                  <Link to="/">
                    <li>
                      <p className="text-black"> Home </p>
                    </li>
                  </Link>
                  <Link to="/about">
                    <li>
                      <p className="text-black"> About </p>
                    </li>
                  </Link>
                  <Link to="/privacy">
                    <li>
                      <p className="text-black"> Privacy Policy </p>
                    </li>
                  </Link>
                  <Link to="terms-and-conditions">
                    <li>
                      <p className="text-black"> Terms and Conditions </p>
                    </li>
                  </Link>
                </ul>
              </nav>
            </div>

            <div className="flex items-center gap-4">
              <div className="sm:flex sm:gap-4 hidden md:block">
                <Link to="/contact">
                  <button
                    className="rounded-md bg-blue-600 hover:bg-blue-700 px-5 py-2.5 text-sm font-medium text-white shadow"
                  >
                    Contact
                  </button>
                </Link>
              </div>

              <div className="block md:hidden">
                <button 
                  className="rounded bg-gray-100 p-2 text-gray-600 transition hover:text-gray-600/75"
                  onClick={toggleMobileMenu}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth="2"
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 6h16M4 12h16M4 18h16" />
                  </svg>
                </button>
              </div>
            </div>
          </div>
        </div>
        {isMobileMenuOpen && (
          <div className="md:hidden bg-white flex flex-col">
            <nav aria-label="Global">
              <ul className="flex flex-col items-start gap-6 text-md font-semibold p-4">
                <Link to="/" onClick={toggleMobileMenu}>
                  <li>
                    <p className="text-black"> Home </p>
                  </li>
                </Link>
                <Link to="/about" onClick={toggleMobileMenu}>
                  <li>
                    <p className="text-black"> About </p>
                  </li>
                </Link>
                <Link to="/privacy" onClick={toggleMobileMenu}>
                  <li>
                    <p className="text-black"> Privacy Policy </p>
                  </li>
                </Link>
                <Link to="terms-and-conditions" onClick={toggleMobileMenu}>
                  <li>
                    <p className="text-black"> Terms and Conditions </p>
                  </li>
                </Link>
                
              </ul>
              <div className="sm:flex sm:gap-4 block md:hidden px-2">
                <Link to="/contact">
                  <button
                    className="rounded-md bg-blue-600 hover:bg-blue-700 px-5 py-2.5 text-sm font-medium text-white shadow"
                  >
                    Contact
                  </button>
                </Link>
              </div>

            </nav>
          </div>
        )}
      </header>
    </div>
  );
};

export default NavBar;
