"use client";
import React, { useState, useCallback } from "react";
import axios from "axios";
import { FaUndo } from "react-icons/fa";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropImage";

const Main = () => {
  const [image, setImage] = useState(null);
  const [width, setWidth] = useState("");
  const [height, setHeight] = useState("");
  const [percentage, setPercentage] = useState(""); 
  const [rotation, setRotation] = useState(0);
  const [resizedImage, setResizedImage] = useState(null);
  const [downloadLink, setDownloadLink] = useState("");
  const [loading, setLoading] = useState(false);
  const [dragging, setDragging] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [showCropper, setShowCropper] = useState(false);
  const [imageUrl, setImageUrl] = useState(""); 

 
  const scrollToFooter = () => {
    const footer = document.querySelector('footer'); 
    if (footer) {
      footer.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const scrollToResult = () => {
    const result = document.querySelector('h5'); 
    if (result) {
      result.scrollIntoView({ behavior: 'smooth' });
    }
  };
  

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
      setTimeout(scrollToFooter, 100);
    }
  };

  const handleUrlChange = (e) => {
    const url = e.target.value;
    setImageUrl(url);
  };

  const handleUrlSubmit = () => {
    if (imageUrl) {
      setImage(imageUrl);
      setTimeout(scrollToFooter, 100);

    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
    if (e.dataTransfer.files.length) {
      const file = e.dataTransfer.files[0];
      setImage(URL.createObjectURL(file));
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(true);
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragging(false);
  };

  const handleResize = async () => {
    if (!image) {
      alert("Please select an image file.");
      return;
      
    }

    let resizeWidth = width;
    let resizeHeight = height;
    if (percentage) {
      const img = new Image();
      img.src = image;
      img.onload = () => {
        const originalWidth = img.width;
        const originalHeight = img.height;
        resizeWidth = Math.round(originalWidth * (percentage / 100));
        resizeHeight = Math.round(originalHeight * (percentage / 100));
        resizeImage(resizeWidth, resizeHeight);
      };
    } else {
      if (!width || !height) {
        alert("Please enter both width and height.");
        return;
      }
      resizeImage(resizeWidth, resizeHeight);
    }
  };

  const resizeImage = async (width, height) => {
    setLoading(true);
    try {
      const blob = await fetch(image).then((r) => r.blob());
      const formData = new FormData();
      formData.append("image", blob);

      const response = await axios.post(
        `https://api.apyhub.com/processor/image/resize/file`,
        formData,
        {
          params: {
            output: "test-sample",
            width: width,
            height: height,
            auto_orientation: "false",
            preserve_format: "true",
            rotation: rotation,
          },
          headers: {
            "apy-token": "APY0v0QbkmA9v39GU2zGEQYFfgHFB0awuaR5yFmbTIowZcaHFnRR0FtqMFYUJrMOgYbXURunU4Nrm",
            "Content-Type": "multipart/form-data",
          },
          responseType: "blob",
        }
      );

      const imageUrl = URL.createObjectURL(response.data);
      setResizedImage(imageUrl);
      setDownloadLink(imageUrl);
    } catch (error) {
      console.error(
        "Error resizing image:",
        error.response ? error.response.data : error.message
      );
      alert(
        "Failed to resize image. Please check your API token and request parameters."
      );
    } finally {
      setLoading(false);
      setTimeout(scrollToResult, 100);

    }
  };

  const handleRotate = () => {
    setRotation((prevRotation) => (prevRotation + 90) % 360);
  };

  const handleCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const handleCrop = async () => {
    if (showCropper) {
      try {
        const croppedImage = await getCroppedImg(image, croppedAreaPixels, rotation);
        setResizedImage(croppedImage);
        setDownloadLink(croppedImage);
        setShowCropper(false);
      } catch (error) {
        console.error("Error cropping image:", error);
        alert("Failed to crop image.");
      }
    } else {
      setShowCropper(true);
    }
  };

  return (
    <main className="w-full mb-20">
      <div className="flex flex-col justify-center items-center">
        <h1
          className="text-5xl font-semibold py-2 text-blue-600"
          style={{ fontFamily: "Oswald, sans-serif" }}
        >
          Image <span className="text-red-600">Resizer</span>
        </h1>
        <p className="text-lg mb-5 text-gray-500">
          Resize your image within one click.
        </p>

        <div
          className={`flex items-center justify-center w-full dropzone ${
            dragging ? "dragging" : ""
          }`}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
          onDragLeave={handleDragLeave}
        >
          <label
            htmlFor="dropzone-file"
            className="flex flex-col items-center justify-center w-[70%] h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50  hover:bg-gray-100 "
          >
            <div className="flex flex-col items-center justify-center pt-5 pb-6">
              <svg
                className="w-8 h-8 mb-4 text-gray-500 "
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 20 16"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5A5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                />
              </svg>
              <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                <span className="font-semibold">Click to upload</span> or drag
                and drop
              </p>
              <p className="text-xs text-gray-500 dark:text-gray-400">
                JPG, PNG, and WebP (Max File Size: 10MB)
              </p>
            </div>
            <input
              id="dropzone-file"
              type="file"
              className="hidden"
              accept="image/*"
              onChange={handleImageChange}
            />
          </label>
        </div>
       <p className="text-gray-500 py-2">or</p>
      <img className="h-5 w-[200px]" src="https://cdn-icons-png.flaticon.com/128/512/512716.png" alt="" />
        <div className="mt-5 flex flex-col items-center">
          <input
            type="text"
            placeholder="Enter image URL"
            value={imageUrl}
            onChange={handleUrlChange}
            className="px-2 py-1 border rounded-lg mr-2 w-[300px] md:w-[500px]"
          />
          <button
            type="button"
            className="px-4 py-2 bg-blue-600 text-white rounded-lg mt-5"
            onClick={handleUrlSubmit}
          >
            Load Image from URL
          </button>
        </div>

        {image && (
          <>
                <footer></footer>

            <div className="mt-5 flex flex-col justify-center">
              <p className="text-lg font-medium mb-2">
                Selected Image:{" "}
                <span className="font-semibold text-blue-600">{image.name}</span>
              </p>
              <div
                className="image-container relative w-[350px]  md:w-[500px] h-auto mb-[20px]"
               
              >
                <img
                className="rounded"
                  src={image}
                  alt="Selected"
                  style={{
                    width: "100%",
                    height: "auto",
                    transform: `rotate(${rotation}deg)`,
                  }}
                />
              </div>
            </div>

            <div className="flex flex-col items-center">
              <div className="flex items-center mb-2">
                <label htmlFor="width" className="mr-2">
                  Width:
                </label>
                <input
                  id="width"
                  type="number"
                  value={width}
                  onChange={(e) => setWidth(e.target.value)}
                  className="border rounded-lg px-2 py-1"
                />
              </div>
              <div className="flex items-center mb-2">
                <label htmlFor="height" className="mr-2">
                  Height:
                </label>
                <input
                  id="height"
                  type="number"
                  value={height}
                  onChange={(e) => setHeight(e.target.value)}
                  className="border rounded-lg px-2 py-1"
                />
              </div>
              <div className="flex items-center mb-2">
                <label htmlFor="percentage" className="mr-2">
                  Resize by %:
                </label>
                <input
                  id="percentage"
                  type="number"
                  value={percentage}
                  onChange={(e) => setPercentage(e.target.value)}
                  className="border rounded-lg px-2 py-1"
                  placeholder="Percentage"
                />
              </div>
              <div className="flex flex-col md:flex-row gap-5">
              <button
                type="button"
                className="px-4 py-2 bg-blue-600 text-white rounded-lg mt-2"
                onClick={handleResize}
                disabled={loading}
              >
                {loading ? "Resizing..." : "Resize Image"}
              </button>
              <button
                type="button"
                className="px-4 py-2 bg-blue-600 text-white rounded-lg mt-2 ml-2"
                onClick={handleRotate}
              >
                Rotate <FaUndo className="inline ml-1" />
              </button>
              <button
                type="button"
                className="px-4 py-2 bg-blue-600 text-white rounded-lg mt-2 ml-2"
                onClick={handleCrop}
              >
                {showCropper ? "Crop" : "Show Cropper"}
              </button>
             
              </div>
            </div>
          </>
        )}

        {showCropper && (
          <div className="mt-5 w-full h-[300px] relative">
            <Cropper
              image={image}
              crop={crop}
              zoom={zoom}
              aspect={4 / 3}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={handleCropComplete}
            />
          </div>
        )}

        {resizedImage && (
          <div className="mt-10 flex flex-col justify-center">
            <h5></h5>
            <h2 className="text-lg font-medium mb-2">Resized Image:</h2>
            <img  src={resizedImage} alt="Resized" className="mb-2 rounded" />
            <a
              href={downloadLink}
              download="resized-image.jpg"
              className="px-4 py-2 bg-indigo-600 hover:bg-indigo-700 text-white rounded-lg"
            >
              Download Resized Image
            </a>
          </div>
        )}
      </div>
    </main>
  );
};

export default Main;